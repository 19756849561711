<template>
  <div id="app"
    class="privacy-policy page-template-default page page-id-3 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="en"/>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <article id="post-3" class="post-3 page type-page status-publish">
              <div class="inside-article">
                <header class="entry-header" aria-label="Content">
                  <h1 class="entry-title" itemprop="headline">
                    About Us
                  </h1>
                </header>

                <div class="entry-content" itemprop="text">
                  <p>
                    At <a href="https://www.gbwhatsapp.chat/" class="jump-url">gbwhatsapp.chat</a>, our goal is to provide accurate and comprehensive information about GBWhatsApp, including their features, advantages, and potential drawbacks. By offering detailed reviews, feature comparisons, and step-by-step guides, we help users to make informed decisions about GB WhatsApp and other similar apps.
                  </p>
                  <p>
                    We are not the developers of the GB WhatsApp APK or any other WhatsApp Mod. Our purpose is solely to serve as an educational and informational resource, delivering valuable insights and guidance to our audience.
                  </p>
                  <p>
                    Thank you for visiting our site! If you have any questions or need assistance, feel free to contact us.
                  </p>
                </div>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="https://www.gbwhatsapp.chat/privacy/">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="https://www.gbwhatsapp.chat/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Privacy',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'privacy',
    };
  },
  mounted () {
    document.documentElement.lang = 'en';
   },
};
</script>
